
import { KonturAccountTransactionDto } from '@torch-dev/kc-server-api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataPagination } from 'vuetify';

@Component({
  name: 'TransactionsTable'
})
export default class extends Vue {
  @Prop()
    transactions!: KonturAccountTransactionDto[];

  page = 1;
  pageCount = 1;

  headers = [{
    text: 'Сумма',
    align: 'start',
    sortable: true,
    value: 'amount'
  }, {
    text: 'Дата и время',
    align: 'start',
    sortable: true,
    value: 'date'
  }];

  onPaginationEvent ({ page, pageCount }: DataPagination) {
    this.page = page;
    this.pageCount = pageCount;
  }

  isAwaiting (item: KonturAccountTransactionDto) {
    return item.status && [
      KonturAccountTransactionDto.status.KONTUR_AWAITING,
      KonturAccountTransactionDto.status.PG_AWAITING
    ].includes(item.status);
  }

  wasRejected (item: KonturAccountTransactionDto) {
    return item.status && [
      KonturAccountTransactionDto.status.KONTUR_REJECTED,
      KonturAccountTransactionDto.status.PG_REJECTED
    ].includes(item.status);
  }

  defineColor (item: KonturAccountTransactionDto): string {
    if (item.status !== KonturAccountTransactionDto.status.KONTUR_COMPLETED) {
      return 'grey';
    } else if (item.amount > 0) {
      return 'green';
    } else {
      return 'red';
    }
  }

  statusIcon (item: KonturAccountTransactionDto): string {
    if (this.isAwaiting(item)) {
      return 'mdi-loading';
    } else if (this.wasRejected(item)) {
      return 'mdi-alert-outline';
    }
    return '';
  }

  errorMessage (item: KonturAccountTransactionDto): string {
    switch (item.status) {
      case KonturAccountTransactionDto.status.KONTUR_REJECTED:
        return 'Платеж отклонен системой.\n Пожалуйста, обратитесь в тех. поддержку, указав код платежа:';
      case KonturAccountTransactionDto.status.PG_REJECTED:
        return 'Платеж отклонен банком.';
      case KonturAccountTransactionDto.status.KONTUR_AWAITING:
        return 'Платеж в обработке.\n Пожалуйста, подождите.';
      case KonturAccountTransactionDto.status.PG_AWAITING:
        return 'Платеж ожидает подтверждения банком.';
      default:
        return '';
    }
  }

  copyToClipboard (item: KonturAccountTransactionDto & { codeCopied: boolean }) {
    if (item.idExternalPaymentGate) {
      navigator.clipboard.writeText(item.idExternalPaymentGate).then(() => {
        item.codeCopied = true;
      });
    }
  }
}
