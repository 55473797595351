
import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AppConfiguration } from '@/services/appConfiguration';
import { ResourceDto } from '@torch-dev/kc-server-api';

@Component({
  name: 'AppFooter',
  computed: mapState(['staticResources', 'configuration'])
})
export default class AppFooter extends Vue {
  staticResources!: ResourceDto[];
  configuration!: AppConfiguration;
}
