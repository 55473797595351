
import PhoneCodeForm from '@/components/phone-code-form.vue';
import { Component, Vue } from 'vue-property-decorator';
import PageMixin from '@/mixins/page-mixin';
import Page from '@/components/page.vue';
import { mapState } from 'vuex';
import { AppResources } from '@/services/appConfiguration';

@Component({
  name: 'AuthView',
  mixins: [PageMixin],
  computed: mapState(['resources']),
  components: { Page, PhoneCodeForm }
})
export default class extends Vue {
  resources!: AppResources;

  mounted () {
    (document.querySelector('#app') as HTMLDivElement).classList.add('auth-view');
  }

  destroyed () {
    (document.querySelector('#app') as HTMLDivElement).classList.remove('auth-view');
  }
}
