import { ResourceDto } from '@torch-dev/kc-server-api';

export function getStaticResources (): ResourceDto[] {
  return [{
    id: -1,
    type: ResourceDto.type.PAGE,
    alias: 'how-to-pay',
    title: 'Процедура оплаты',
    url: '/resources/how-to-pay.html'
  }, {
    id: -1,
    type: ResourceDto.type.FILE,
    alias: 'rules',
    title: 'Правила пользования ски-пассом',
    url: 'https://srkvg.ru/pravila-povedeniya-na-gornolyzhnom-sklone-sportivnogo-kompleksa-vorobevy-gory/'
  }, {
    id: -1,
    type: ResourceDto.type.PAGE,
    alias: 'user-agreement',
    title: 'Пользовательское соглашение',
    url: '/resources/user-agreement.html'
  }, {
    id: -1,
    type: ResourceDto.type.PAGE,
    alias: 'privacy-policy',
    title: 'Политика в отношении обработки персональных данных',
    url: '/resources/privacy-policy.html'
  }];
}
