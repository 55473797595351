

import { mapState } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import Bio from '@/views/dashboard/bio.vue';
import Notifications from '@/components/notifications.vue';
import SkipassCardsSlider from '@/views/dashboard/skipass-cards-slider.vue';
import SkipassManagement from '@/views/dashboard/skipass-management.vue';
import Transactions from '@/views/dashboard/transactions.vue';
import { ClientDto, KonturAccountDto } from '@torch-dev/kc-server-api';
import SkipassCardBalance from '@/views/dashboard/skipass-card-balance.vue';
import DeviceTypeMixin from '@/mixins/device-type-mixin';
import Packages from '@/views/dashboard/packages.vue';
import ModalRefill from '@/components/modals/modal-refill.vue';
import ModalPackages from '@/components/modals/modal-packages.vue';
import ModalAccountRename from '@/components/modals/modal-account-rename.vue';
import ModalAccountDetach from '@/components/modals/modal-account-detach.vue';
import ModalAccountLock from '@/components/modals/modal-account-lock.vue';
import ModalRefillResult from '@/components/modals/modal-refill-result.vue';

@Component({
  name: 'Profile',
  computed: mapState(['client']),
  components: { ModalRefillResult, ModalAccountLock, ModalAccountDetach, ModalAccountRename, ModalPackages, ModalRefill, Packages, SkipassCardBalance, Transactions, Bio, SkipassCardsSlider, SkipassManagement, Notifications },
  mixins: [DeviceTypeMixin]
})
export default class extends Vue {
  client!: ClientDto | null;
  currentAccount: KonturAccountDto | null = null;
}
