import { Component, Vue } from 'vue-property-decorator';

type DeviceType = 'mobile-s' | 'mobile-m' | 'mobile-l' | 'tablet' | 'laptop';

@Component
export default class extends Vue {
  deviceType: DeviceType = this.guessDeviceType();

  protected get isMobileDevice () {
    return ['mobile-s', 'mobile-m', 'mobile-l'].includes(this.deviceType);
  }

  private guessDeviceType (): DeviceType {
    const width = window.innerWidth;
    if (width <= 320) return 'mobile-s';
    if (width <= 375) return 'mobile-m';
    if (width <= 430) return 'mobile-l';
    if (width <= 1024) return 'tablet';
    return 'laptop';
  }

  private onWindowResize () {
    this.deviceType = this.guessDeviceType();
  }

  mounted () {
    window.addEventListener('resize', () => this.onWindowResize());
  }

  destroyed () {
    window.removeEventListener('resize', () => this.onWindowResize());
  }
}
