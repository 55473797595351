
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { useApi } from '@/services/api';
import { ClientDto } from '@torch-dev/kc-server-api';
import { AppConfiguration } from '@/services/appConfiguration';
import Modal from '@/components/modals/modal.vue';

enum Action {
  Create,
  Attach
}

@Component({
  name: 'SkipassManagement',
  components: { Modal },
  computed: mapState(['client', 'configuration'])
})
export default class extends Vue {
  api = useApi();
  client!: ClientDto;
  configuration!: AppConfiguration;
  selectedAction: Action | null = null;
  showDialog = false;

  cardCode = '';
  cardName = '';

  Action = Action;
  errorMessage = '';
  processingAction = false;

  get isValid (): boolean {
    switch (this.selectedAction) {
      case Action.Create: return this.cardName.trim().length > 0;
      case Action.Attach: return this.cardName.trim().length > 0 && this.cardCode.trim().length > 1;
    }
    return false;
  }

  onAttachAccountClick () {
    this.showDialog = true;
    this.selectedAction = Action.Attach;
  }

  onCreateAccountClick () {
    this.showDialog = true;
    this.selectedAction = Action.Create;
  }

  async onContinueClick () {
    this.processingAction = true;
    try {
      switch (this.selectedAction) {
        case Action.Create:
          await this.api.clients.createAccount(this.client.id, {
            name: this.cardName,
            isMutable: true,
            isRefillable: true
          });
          this.$root.$emit('account-created');
          break;
        case Action.Attach:
          await this.api.clients.attachAccount(this.client.id, {
            name: this.cardName,
            code: this.cardCode,
            isMutable: true,
            isRefillable: true
          });
          this.$root.$emit('account-attached');
          break;
      }
      this.showDialog = false;
    } catch (e) {
      this.errorMessage = 'Невозможно выполнить запрос. Пожалуйста, попробуйте позже';
    }
    this.processingAction = false;
  }

  onDialogClose () {
    this.cardCode = '';
    this.cardName = '';
    this.errorMessage = '';
    this.processingAction = false;
    this.showDialog = false;
  }
}
