
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { mapActions, mapState } from 'vuex';
import { useApi } from '@/services/api';
import { AppConfiguration } from '@/services/appConfiguration';

const NUMBER_KEYS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
const BACKSPACE_KEY = 'Backspace';
const PHONE_INPUT_ID = 'phone-input';

@Component({
  computed: mapState(['configuration']),
  methods: mapActions(['authorizeClientByPhoneCode'])
})
export default class extends Vue {
  @Prop({ required: true })
    event!: string;

  configuration!: AppConfiguration;

  get useCall () {
    return this.configuration.authMethod === 'call';
  }

  get useSms () {
    return this.configuration.authMethod === 'sms';
  }

  step = 1;

  // first step
  phone = '';
  phoneFormValid = false;
  codeIsSending = false;
  clientExists = false;

  // second step
  codeLength = 4;
  codeDigits: string[] = new Array(this.codeLength).fill('');
  codeFocused = false;
  codeIsCompleted = false;
  codeIsChecking = false;
  agreement = false;

  // common props

  errorMessage: string | null = null;
  timeout = 0;
  interval: number | null = null;

  api = useApi();

  authorizeClientByPhoneCode!: (params: { phone: string, code: string, isExistingUser: boolean }) => Promise<void>;

  phoneRules = [
    (v: string) => {
      const isIncomplete = (this.isPhoneFocused() && this.getPhoneDigits(v).length < 10);
      const isValid = this.phoneIsValid(v);
      return isIncomplete || isValid || 'Введен некорректный номер';
    }
  ];

  isPhoneFocused () {
    return document.activeElement?.id === PHONE_INPUT_ID;
  }

  phoneIsValid (value: string): boolean {
    return (
      this.phoneLengthIsValid(value) &&
      this.phoneOperatorCodeIsValid(value) &&
      isValidPhoneNumber(`+7${value}`)
    );
  }

  getPhoneDigits (value: string): string {
    return value.replaceAll(/\D/g, '');
  }

  phoneLengthIsValid (value: string) {
    return this.getPhoneDigits(value).length === 10;
  }

  phoneOperatorCodeIsValid (value: string) {
    return this.getPhoneDigits(value).toString().charAt(0) === '9';
  }

  get phoneWithCountryCode (): number {
    return parseInt('7' + this.getPhoneDigits(this.phone));
  }

  get codeFormValid (): boolean {
    return (this.clientExists || this.agreement) && this.codeIsCompleted && !this.codeIsChecking;
  }

  onCodeInput (event: KeyboardEvent, index: number) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    if (![...NUMBER_KEYS, BACKSPACE_KEY].includes(event.key)) {
      return;
    }
    if (event.key === 'Backspace') {
      if (this.codeDigits[index] !== '') {
        this.codeDigits[index] = '';
      } else if (index > 0) {
        this.codeDigits[index - 1] = '';
        this.focusDigit(index - 1);
      }
      this.checkCodeIsCompleted();
    } else {
      if (!this.codeDigits.includes('')) {
        this.$forceUpdate();
        return;
      }

      this.codeDigits[index] = event.key;
      this.focusDigit(index + 1);
      this.checkCodeIsCompleted();
    }
    this.$forceUpdate();
  }

  checkCodeIsCompleted () {
    this.codeIsCompleted = this.codeDigits.reduce((d, s) => d + s, '').length === this.codeLength;
  }

  focusDigit (index: number) {
    if (index < 0 || index >= this.codeLength) {
      return;
    }
    // eslint-disable-next-line
    // @ts-ignore
    this.$refs['digit' + (index)][0].focus();
  }

  onPhoneInput (event: KeyboardEvent) {
    const modifierPressed = event.ctrlKey || event.altKey || event.metaKey;

    if (event.key.length > 1 || modifierPressed) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    if (!NUMBER_KEYS.includes(event.key)) {
      return;
    }
    const selectionText = document.getSelection()?.toString();
    if (selectionText && this.phone.includes(selectionText)) {
      this.phone = this.phone.replace(selectionText, '');
    }
    if (this.phoneLengthIsValid(this.phone)) {
      return;
    }
    this.phone += event.key;
    this.phone = new AsYouType('RU').input(this.phone);
  }

  onCodeClick () {
    this.codeFocused = true;
    const firstUnfilled = this.codeDigits.findIndex(d => d === '');
    const focusIndex = firstUnfilled > -1 ? firstUnfilled : this.codeLength - 1;
    this.focusDigit(focusIndex);
  }

  async onSendCodeClick (): Promise<void> {
    this.codeIsSending = true;
    await this.sendAuthCode();
    this.codeIsSending = false;
  }

  async sendAuthCode (): Promise<void> {
    try {
      const { codeTimeout, isExistingUser } = await this.api.clientAuth.sendCode({ phone: this.phoneWithCountryCode });
      this.step = 2;
      this.errorMessage = null;
      this.clientExists = isExistingUser;
      this.saveNextSendingAttempt(codeTimeout);
      this.initializeTimeoutChecker();
      this.$nextTick(() => this.focusDigit(0));
    } catch (e) {
      // TODO уточнить ошибку
      this.errorMessage = this.useSms
        ? 'Не удалось отправить сообщение. Пожалуйста, попробуйте позже'
        : 'Не удалось совершить звонок. Пожалуйста, попробуйте позже';
    }
  }

  saveNextSendingAttempt (timeout: number): void {
    localStorage.setItem('nextSendingAttempt', (new Date().getTime() + timeout * 1000).toString());
  }

  getNextSendingAttempt (): number {
    return parseInt(localStorage.getItem('nextSendingAttempt') ?? '') ?? new Date().getTime();
  }

  initializeTimeoutChecker (): void {
    this.interval = setInterval(() => {
      this.timeout = Math.max(0, Math.floor((this.getNextSendingAttempt() - new Date().getTime()) / 1000) || 0);
      if (this.interval && this.timeout === 0) clearInterval(this.interval);
    }, 1000);
  }

  authWithCode () {
    this.codeIsChecking = true;
    this.authorizeClientByPhoneCode({
      phone: this.phoneWithCountryCode.toString(),
      code: this.codeDigits.reduce((d, s) => d + s, ''),
      isExistingUser: this.clientExists
    })
      .then(() => this.$root.$emit(this.event))
      .catch((e: Error) => {
        this.errorMessage = 'Введен неправильный код или время действия кода истекло';
      })
      .finally(() => {
        this.codeIsChecking = false;
      });
  }

  @Watch('errorMessage')
  onErrorMessageChanged (error: string | null) {
    if (typeof error === 'string') {
      setTimeout(() => {
        this.errorMessage = null;
      }, 7500);
    }
  }

  resetForm () {
    this.step = 1;
    this.codeDigits = new Array(this.codeLength).fill('');
    this.codeFocused = false;
    this.codeIsSending = false;
    this.codeIsChecking = false;
    this.errorMessage = null;
    this.timeout = 0;
    this.agreement = false;
  }

  initializeInput () {
    const phoneInput = (this.$refs.phoneField as Vue)?.$el.querySelector('input');
    if (phoneInput) {
      phoneInput.id = PHONE_INPUT_ID;
      // phoneInput.min = '10'
      // phoneInput.max = '10'
    }
  }

  mounted () {
    this.initializeTimeoutChecker();
    this.initializeInput();
    this.resetForm();
  }
}
