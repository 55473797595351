
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BonusType } from '@/views/season-tickets/model/bonus-type';
import { SeasonTicketInfo } from '@/views/season-tickets/model/season-ticket-info';

@Component({
  name: 'SeasonTicket'
})
export default class extends Vue {
  BonusType = BonusType;

  @Prop({ required: true })
    info!: SeasonTicketInfo;

  get backgroundImageUrl () {
    return require(`../../assets/${this.info.image}`);
  }

  get buttonText (): string {
    return this.info.bonusType === BonusType.Relative ? 'Пополнить' : 'Приобрести';
  }

  get ticketType () {
    return this.info.bonusType === BonusType.Relative ? 'Абонемент' : 'Абонемент';
  }

  get bonusMoney (): string | null {
    if (this.info.priceTo) {
      return `до ${Math.round(this.info.priceTo / 100 * this.info.bonus)}`;
    } else if (this.info.priceFrom) {
      return `от ${Math.round(this.info.priceFrom / 100 * this.info.bonus)}`;
    } else if (this.info.price) {
      return `+ ${this.info.bonus.toString()}`;
    }
    return null;
  }
}
