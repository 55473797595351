
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { KonturAccountDto, PackageDto } from '@torch-dev/kc-server-api';
import { AppConfiguration } from '@/services/appConfiguration';
import DeviceTypeMixin from '@/mixins/device-type-mixin';

@Component({
  name: 'SkipassCard',
  computed: mapState(['configuration', 'availablePackages']),
  mixins: [DeviceTypeMixin]
})
export default class extends Vue {
  configuration!: AppConfiguration;
  availablePackages!: PackageDto[];

  @Prop()
    account!: KonturAccountDto;

  get isLocked () {
    return this.account.cards[0]?.isLocked ?? false;
  }

  get rubBalance () {
    return this.account.balance.find((b) => b.currency === 'RUB')?.balance || 0;
  }

  get bonusBalance () {
    return this.account.balance.find((b) => b.currency === 'BONUS')?.balance || 0;
  }

  onRefillButtonClick () {
    this.$root.$emit('refill-account', { accountId: this.account.id });
  }

  onPackagesButtonClick () {
    this.$root.$emit('select-package', { account: this.account });
  }

  onRenameAccountClick () {
    this.$root.$emit('rename-account', { account: this.account });
  }

  onLockAccountClick () {
    this.$root.$emit('lock-account', { account: this.account });
  }

  onDetachAccountClick () {
    this.$root.$emit('detach-account', { account: this.account });
  }
}
