/* TODO */
/* This file is mock. We'll use it until we have functionality of webapps configuration on backend */
/* Types AppConfiguration and AppResources should be replaced with backend dtos then */
/* Assume that return values will be overwritten for each particular project individually */

export type AppConfiguration = {
  title: string,
  authMethod: 'sms' | 'call' | 'password',
  features: {
    clientCard: {
      allowCreate: boolean,
      allowAttach: boolean,
      allowDetach: boolean,
      allowRefill: boolean,
      allowBlock: boolean,
    },
    paymentMethods: {
      Visa: boolean,
      MasterCard: boolean,
      MIR: boolean,
      JSB: boolean,
    },
    loyaltyProgramEnabled: boolean,
    preSalesProgramEnabled: boolean,
  }
}

export type AppResources = Partial<{
  logoUrl: string,
  faviconUrl: string,
  backgroundUrl: string,
  colorTheme: Partial<{
    primary: string,
    secondary: string,
    accent: string,
    error: string,
  }>
}>;

export function getAppConfiguration (): AppConfiguration {
  return {
    title: 'Горнолыжный комплекс "Воробьевы Горы"',
    authMethod: 'sms',
    features: {
      clientCard: {
        allowCreate: true,
        allowAttach: true,
        allowDetach: true,
        allowRefill: true,
        allowBlock: true
      },
      paymentMethods: {
        Visa: true,
        MasterCard: true,
        MIR: false,
        JSB: true
      },
      loyaltyProgramEnabled: true,
      preSalesProgramEnabled: false
    }
  };
}

export function getAppResources (): AppResources {
  return {
    logoUrl: '/pictures/logo.svg',
    faviconUrl: '/favicon.svg',
    backgroundUrl: '/pictures/background.png',
    colorTheme: {
      primary: '#3ab0d5',
      secondary: '#b0bec5',
      accent: '#8c9eff',
      error: '#b71c1c'
    }
  };
}
