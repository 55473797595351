

import { Component, Vue } from 'vue-property-decorator';
import { KonturAccountTransactionDto } from '@torch-dev/kc-server-api';

type Notification = {
  id: number,
  icon: string,
  type: 'info' | 'success' | 'error',
  text: string,
  code?: string
}

@Component({
  name: 'Notifications'
})
export default class extends Vue {
  codeCopied = false;
  notifications: Notification[] = [];

  mounted () {
    // TODO Порефакторить. Типизировать события и пэйлоад

    this.$root.$on('account-refill-successful', (transaction: KonturAccountTransactionDto | null) => {
      transaction && this.notifications.push({
        id: new Date().getTime(),
        icon: 'mdi-check-circle',
        type: 'success',
        text: `Баланс счета пополнен на ${transaction.amount} руб.`
      });
    });

    this.$root.$on('account-refill-failed-payment-gate', (transaction: KonturAccountTransactionDto | null) => {
      transaction && this.notifications.push({
        id: new Date().getTime(),
        icon: 'mdi-alert-circle',
        type: 'error',
        text: 'Платеж отклонен банком.'
      });
    });

    this.$root.$on('account-refill-failed-kontur', (transaction: KonturAccountTransactionDto | null) => {
      transaction && this.notifications.push({
        id: new Date().getTime(),
        icon: 'mdi-alert-circle',
        type: 'error',
        code: transaction.idExternalPaymentGate,
        text: `
          Платеж отклонен системой.
          Для оформления возврата, пожалуйста, обратитесь в тех. поддержку и назовите код платежа:
        `
      });
    });

    //
    // this.$api.clientService.onBonusAccountRefill(({ amount }) => {
    //   this.notifications.push({
    //     id: new Date().getTime(),
    //     icon: 'mdi-gift',
    //     type: 'success',
    //     text: `Бонусный счет пополнен на ${amount} руб.`
    //   });
    // });
    //
  }

  copyCode (code: string) {
    navigator.clipboard.writeText(code).then(() => {
      this.codeCopied = true;
    });
  }
}
