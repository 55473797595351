
import { Vue, Component } from 'vue-property-decorator';
import PageMixin from '@/mixins/page-mixin';

@Component({
  name: 'Page',
  mixins: [PageMixin]
})
export default class Page extends Vue {
}
