
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SeasonTicketInfo } from '@/views/season-tickets/model/season-ticket-info';
import SeasonTicket from '@/views/season-tickets/season-ticket.vue';

@Component({
  name: 'SeasonTicketsView',
  components: { SeasonTicket }
})
export default class extends Vue {
  @Prop({ required: true })
    info!: SeasonTicketInfo[];

  onCardBtnClicked (info: SeasonTicketInfo) {
    this.$emit('card-btn-clicked', info);
  }
}
