import { KcServerApi, OpenAPIConfig } from '@torch-dev/kc-server-api';
import { getStoredUserDate } from '@/services/localStorage';

const token = getStoredUserDate()?.token ?? '';

const defaultConfig: Partial<OpenAPIConfig> = {
  BASE: 'https://skipass-srkvg.ru/api',
  TOKEN: token
};

const api = new KcServerApi(defaultConfig);

export function useApi () {
  return api;
}

export function setApiConfig (config: Partial<OpenAPIConfig>) {
  Object.assign(api, new KcServerApi({ ...defaultConfig, ...config }));
}
