

import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { DataTableHeader } from 'vuetify';
import InlineInput from '@/views/dashboard/inline-input.vue';
import { ClientDto } from '@torch-dev/kc-server-api';
import { useApi } from '@/services/api';

type BioConfig = {
    field: keyof ClientDto,
    title: string,
    formatter?: (v: string) => string
}[]

type TableItem = {
  key: string;
  field: string;
  value: string | null;
}

const config: BioConfig = [{
  field: 'firstName',
  title: 'Имя'
}, {
  field: 'lastName',
  title: 'Фамилия'
}, {
  field: 'email',
  title: 'E-mail'
}];

@Component({
  name: 'Bio',
  components: { InlineInput },
  computed: mapState(['client']),
  methods: mapActions(['loadAuthorizedClient', 'logoutClient'])
})
export default class extends Vue {
  client!: ClientDto;
  startRoute!: string;

  api = useApi();

  loadAuthorizedClient!: () => Promise<void>;
  logoutClient!: () => void;

  get tableHeaders (): DataTableHeader[] {
    return [{
      text: 'key',
      value: 'key'
    }, {
      text: 'value',
      value: 'value'
    }];
  }

  get tableItems (): TableItem[] {
    return config
      .map(item => ({
        key: item.title,
        field: item.field,
        value: this.client[item.field] ? (this.client[item.field] as keyof ClientDto).toString() : null,
        formatter: item.formatter
      }));
  }

  async updateClient (field: keyof ClientDto, value: string) {
    await this.api.clients.update(this.client.id, { ...this.client, [field]: value });
    await this.loadAuthorizedClient();
  }

  onLogOutClick (): void {
    this.logoutClient();
    this.$router.push(this.startRoute);
  }
}
