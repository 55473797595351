
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import Page from '@/components/page.vue';
import { mapState } from 'vuex';
import { ResourceDto } from '@torch-dev/kc-server-api';

@Component({
  name: 'CustomPage',
  components: { Page },
  computed: mapState(['staticResources'])
})
export default class CustomPage extends Vue {
  @Prop() alias!: string;

  staticResources!: ResourceDto[];
  pageHtml: string | null = null;

  get resource (): ResourceDto | null {
    return this.staticResources.find(({ alias }) => alias === this.alias) ?? null;
  }

  @Watch('resource')
  private async load () {
    if (this.resource?.type === ResourceDto.type.FILE) location.replace(this.resource.url);
    if (this.resource?.type === ResourceDto.type.PAGE) this.pageHtml = await fetch(this.resource.url).then((res) => res.text());
  }

  mounted () {
    this.load();
  }
}
