import Vue from 'vue';
import Vuex from 'vuex';
import App from './app.vue';
import router from './router';
import storeOptions from './store';
import vuetify from '@/vuetify';
import { MoneyFormatter } from '@/filters/money-formatter';
import { PhoneNumberWrapper } from '@/filters/phone-number-wrapper';
import { EmailWrapper } from '@/filters/email-wrapper';
import { DateFormatter } from '@/filters/date-formatter';
import { TimeFormatter } from '@/filters/time-formatter';

Vue.config.productionTip = false;

Vue.filter('formatDate', DateFormatter);
Vue.filter('formatTime', TimeFormatter);
Vue.filter('formatMoney', MoneyFormatter);
Vue.filter('wrapEmail', EmailWrapper);
Vue.filter('wrapPhoneNumber', PhoneNumberWrapper);

Vue.use(Vuex);

new Vue({
  router,
  vuetify,
  store: new Vuex.Store(storeOptions),
  render: h => h(App)
}).$mount('#app');
