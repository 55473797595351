
import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/modals/modal.vue';
import { ClientDto, KonturAccountDto } from '@torch-dev/kc-server-api';
import { useApi } from '@/services/api';
import { mapState } from 'vuex';

type ModalAccountDetachParams = {
  account: KonturAccountDto
}

const api = useApi();

@Component({
  name: 'ModalCardDetach',
  computed: mapState(['client']),
  components: { Modal }
})
export default class extends Vue {
  client!: ClientDto;

  showDialog = false;
  account: KonturAccountDto | null = null;

  async onDetachClick () {
    if (!this.account) return;

    await api.clients.detachAccount(this.client.id, this.account?.id);
    this.$root.$emit('account-updated');
    this.showDialog = false;
  }

  onCancelClick () {
    this.showDialog = false;
  }

  mounted () {
    this.$root.$on('detach-account', ({ account }: ModalAccountDetachParams) => {
      this.showDialog = true;
      this.account = account;
    });
  }
}
