
import { Component, Prop } from 'vue-property-decorator';
import DeviceTypeMixin from '@/mixins/device-type-mixin';
import { computed } from 'vue';

@Component({
  mixins: [DeviceTypeMixin]
})
export default class Modal extends DeviceTypeMixin {
  @Prop() isVisible!: boolean;
  @Prop() maxWidth!: number | null;

  get maxDialogWidth () {
    return this.maxWidth ?? 500;
  }
}
