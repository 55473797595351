
import { KonturAccountBalanceDto } from '@torch-dev/kc-server-api';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({ name: 'SkipassCardBalance' })
export default class extends Vue {
  @Prop() balanceList!: KonturAccountBalanceDto[];
}

