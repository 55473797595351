
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClientDto, KonturAccountDto, KonturAccountTransactionDto } from '@torch-dev/kc-server-api';
import { useApi } from '@/services/api';
import { mapState } from 'vuex';
import Modal from '@/components/modals/modal.vue';

@Component({
  name: 'ModalRefillResult',
  components: { Modal },
  computed: mapState(['client'])
})
export default class extends Vue {
  client!: ClientDto;

  @Prop({ required: true })
    account!: KonturAccountDto;

  showDialog = false;
  transaction: KonturAccountTransactionDto | null = null;
  errorMessage: string | null = null;

  get paymentIsFinished () {
    return this.transaction !== null;
  }

  get paymentIsSuccessful () {
    return this.transaction !== null && this.transaction.status === KonturAccountTransactionDto.status.KONTUR_COMPLETED;
  }

  get paymentIsFailed () {
    return this.errorMessage !== null || this.paymentGateTransactionFailed || this.konturTransactionFailed;
  }

  get paymentGateTransactionFailed () {
    return this.transaction?.status === KonturAccountTransactionDto.status.PG_REJECTED;
  }

  get konturTransactionFailed () {
    return this.transaction?.status === KonturAccountTransactionDto.status.KONTUR_REJECTED;
  }

  mounted (): void {
    let transactionId: number | null;
    try {
      const queryParam = this.$router.currentRoute?.query?.transactionId;
      transactionId = (typeof queryParam === 'string' && parseInt(queryParam)) || null;
    } catch (e) {
      transactionId = null;
    }
    if (transactionId) this.loadTransaction(transactionId);
  }

  async loadTransaction (transactionId: number) {
    this.showDialog = true;
    this.transaction = await this.waitUntilTransactionFinished(transactionId);
  }

  async waitUntilTransactionFinished (transactionId: number): Promise<KonturAccountTransactionDto> {
    const api = useApi();
    return new Promise((resolve, reject) => {
      const requestTransaction = async () => {
        let transaction: KonturAccountTransactionDto;
        try {
          transaction = await api.clients.getTransaction(transactionId, this.account.id, this.client.id);
          if (transaction?.status && [
            KonturAccountTransactionDto.status.PG_REJECTED,
            KonturAccountTransactionDto.status.KONTUR_REJECTED,
            KonturAccountTransactionDto.status.KONTUR_COMPLETED
          ].includes(transaction.status)) {
            resolve(transaction);
          } else {
            setTimeout(requestTransaction, 2000);
          }
        } catch (e) {
          this.errorMessage = (e as Error).message ?? null;
        }
      };
      return requestTransaction();
    });
  }

  onOkBtnClick () {
    this.showDialog = false;
    this.$root.$emit('payment-ok-clicked');
    this.$router.push(this.$router.currentRoute.path);

    // TODO Нужно для уведомлений. Посмотреть по UX, нужны ли теперь уведомления вообще
    // if (this.paymentIsSuccessful) this.$root.$emit('account-refill-successful', this.transaction);
    // if (this.paymentGateTransactionFailed) this.$root.$emit('account-refill-failed-payment-gate', this.transaction);
    // if (this.konturTransactionFailed) this.$root.$emit('account-refill-failed-kontur', this.transaction);
  }
}
