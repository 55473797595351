

import { Component, Prop, Vue } from 'vue-property-decorator';
import TransactionsTable from '@/views/dashboard/transactions-table.vue';
import { KonturAccountTransactionDto } from '@torch-dev/kc-server-api';

@Component({
  name: 'Activity',
  components: { TransactionsTable }
})
export default class extends Vue {
    @Prop()
      list!: KonturAccountTransactionDto[];

    currentTab = 0;
    tabs = ['Пополнения', 'Списания'];

    get visibleTransactions (): KonturAccountTransactionDto[] {
      return this.list
        .filter((t) => (
          t.status === KonturAccountTransactionDto.status.KONTUR_COMPLETED ||
          t.status === KonturAccountTransactionDto.status.KONTUR_REJECTED
        ));
    }

    get refills (): KonturAccountTransactionDto[] {
      return this.visibleTransactions.filter((t) => t.amount > 0 && t.currency === 'RUB');
    }

    get debts (): KonturAccountTransactionDto[] {
      return this.visibleTransactions.filter((t) => t.amount < 0);
    }

  // get statusDict (): Record<TransactionStatus, TransactionStatusDictItem> {
  //   return TRANSACTION_STATUS_DICT
  // }
}
